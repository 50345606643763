<template>
  <page-container class="store-info-container">
<!--    <div v-if="agencyStatus === '01'">-->
<!--      <div class="tips">-->
<!--        <span>累计推广金额还差{{ difference }}元可以开设店铺</span>-->
<!--      </div>-->
      <!-- 注册信息 -->
      <van-form
          @submit="onSubmit"
          label-width="7.5em"
          input-align="right"
      >

        <div class="store-info-card-item">
          <van-field
              v-model.trim="registerForm.agencyName"
              name="agencyName"
              label="店铺名称"
              placeholder="店铺名称"
              readonly
              :rules="[{ required: true, message: '请输入店铺名称' }]"
          />
<!--          <van-field-->
<!--              v-model.trim="registerForm.username"-->
<!--              name="username"-->
<!--              label="手机号码"-->
<!--              placeholder="手机号码"-->
<!--              :rules="[{ required: true, message: '请输入手机号码' }]"-->
<!--          />-->
          <van-field
              v-model.trim="registerForm.password"
              name="password"
              type="password"
              label="密码"
              placeholder="密码"
              :rules="[{ required: true, message: '请输入密码' }]"
          />
          <van-field
              v-model.trim="confirmPsw"
              name="confirmPsw"
              type="password"
              label="确认密码"
              placeholder="确认密码"
              :rules="[{ required: true, message: '请输入确认密码' }, {validator: () => {
                return registerForm.password === confirmPsw;
              }, message: '确认密码与密码不相符'}]"
          />
        </div>

        <div class="btn-container">
          <van-button round block type="primary" native-type="submit">注册</van-button>
        </div>
      </van-form>

<!--    </div>-->
<!--
    <div v-if="agencyStatus === '02' || agencyStatus === '03'">
      <div class="shopTip">
        检测您已有店铺，请根据以下提示前往
      </div>
      <div class="shopBox">
        你的店铺名为<span>{{ shopInfo.name }}</span>,请使用电脑浏览器登录以下网址管理店铺<span>{{ VUE_APP_ADMIN_URL }}</span>
      </div>

      <div class="btn-container">
        <van-button
            id="copyBtn"
            round block type="primary"
        >点我复制网址
        </van-button>
      </div>
    </div>

-->
  </page-container>
</template>

<script>
import { Dialog, Toast } from 'vant';
import Clipboard from 'clipboard';
import { openShopInfo } from '@/api/user';
import { getUpgradeDifference, registeredStore } from '@/api/shop';
import { getUserInfo } from '@/api/user';
import { GET_LOGIN_REFEREE_ID } from '@/util/storage';
import { VUE_APP_ADMIN_URL } from '@/config';

export default {
  name: 'storeInfo',
  data() {
    return {
      difference: '', // 店铺升级差额
      shopInfo: {},
      userInfo: {},

      registerForm: {
        agencyName: '', // 店铺名称
        username: '', // 手机号码
        password: '', // 密码
        refereeId: GET_LOGIN_REFEREE_ID(), // 推荐人id
      },
      confirmPsw: '',
    };
  },
  computed: {
    agencyStatus() {
      return this.userInfo.agencyStatus;
    },
    VUE_APP_ADMIN_URL() {
      return VUE_APP_ADMIN_URL;
    }
  },
  created() {
    // 店铺升级差额查询
    getUpgradeDifference().then(res => {
      this.difference = res || '';
    });
    // 开通店铺信息查询
    openShopInfo().then(res => {
      this.shopInfo = res || {};
    });
    // 用户信息查询
    getUserInfo().then(res => {
      this.userInfo = res || {};
      let { mobile } = this.userInfo;
      if (!mobile) {
        Dialog.alert({
          message: `请先完善个人信息`,
          confirmButtonText: '去完善',
        }).then(() => {
          this.$router.replace('/userInfo');
        });
      } else {
        Object.assign(this.registerForm, {
          agencyName: mobile,
          username: mobile,
        })
      }
    });
  },
  mounted() {
    let clipboard = new Clipboard('#copyBtn', {
      // target: () => this.$refs.copyTarget,
      text: () => VUE_APP_ADMIN_URL,
    });
    clipboard.on('success', e => {
      Toast('复制完成');
      e.clearSelection();
    });
  },
  methods: {
    onSubmit() {
      Dialog.confirm({
        title: `确认注册么？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            registeredStore({ ...this.registerForm }).then(res => {
              done();
              if (res === true) {
                Toast('注册成功');
                this.$router.back();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="less" scoped>
.store-info-container {
  .store-info-card-item {
    margin: 10px;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
  }

  .btn-container {
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 100%;
  }

  .tips {
    width: 100%;
    height: 50px;
    text-align: center;
    line-height: 50px;

    span {
      font-weight: 600;
      color: red;
    }
  }

  .shopTip {
    padding: 10px;
    line-height: 2;
    font-size: 16px;
    color: red;
  }

  .shopBox {
    padding: 10px;
    line-height: 2;
    font-size: 14px;
    color: #666;

    & > span {
      padding: 0 10px;
      font-size: 16px;
      color: #000;
      font-weight: bolder;
    }
  }

}
</style>
